import type { ComponentType } from "react";
import type { IntersectionOptions } from "react-intersection-observer";

import { useState } from "react";
import { useInView } from "react-intersection-observer";
import { useIsomorphicLayoutEffect } from "react-use";

interface LazyTillViewProps extends Record<string, unknown> {
  component: ComponentType<Record<string, unknown>>;
  inViewOptions?: IntersectionOptions;
  theme?: "dark" | "light";
}

function LazyTillView({
  component,
  inViewOptions,
  theme = "dark",
  ...props
}: LazyTillViewProps) {
  const Component = component;
  const [ref, inView] = useInView({
    // should be in view x amount of pixels before its scrolled to
    rootMargin: "300px",
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    threshold: 0.2,
    triggerOnce: true,
    ...inViewOptions,
  });
  const [seen, setSeen] = useState(false);

  useIsomorphicLayoutEffect(() => {
    if (inView && !seen) {
      setSeen(true);
    }
  }, [inView, seen]);

  return (
    <div ref={ref}>
      {inView || seen ? (
        <Component theme={theme} {...props} />
      ) : (
        <div
          style={{
            backgroundColor: theme === "dark" ? "black" : "white",
            height: "100vh",
            width: "100vw",
          }}
        />
      )}
    </div>
  );
}

export const lazyTillView = (
  component: LazyTillViewProps["component"],
  options?: LazyTillViewProps["inViewOptions"],
) => {
  function WrappedComponent(props: Record<string, unknown>) {
    return (
      <LazyTillView component={component} inViewOptions={options} {...props} />
    );
  }

  return WrappedComponent;
};

export default LazyTillView;
