/* eslint-disable @typescript-eslint/naming-convention */
export function getSchemaJsonLd() {
  return {
    "@context": "https://schema.org",
    "@type": "FinancialService",
    address: {
      "@type": "PostalAddress",
      addressCountry: "IN",
      addressLocality: "Bengaluru",
      addressRegion: "Karnataka",
      postalCode: "560037",
      streetAddress:
        "RA 307 Purva Rivera, Marathahalli Bridge, Munekolala, Bengaluru - 560037, KA",
    },
    contactPoint: {
      "@type": "ContactPoint",
      areaServed: "IN",
      availableLanguage: ["en", "hi"],
      contactType: "Customer Service",
      telephone: "+91-86180 93364",
    },
    description:
      "Goalteller is a SEBI-registered financial planning app that helps you manage, grow, and visualize your wealth. Get personalized investment strategies, goal tracking, and expert advice on Mutual Funds, Bonds, PMS, and more—all in one platform.",
    image: `https://staging.goalteller.com/images/logo.svg`,
    logo: `https://staging.goalteller.com/images/logo.svg`,
    name: "Goalteller",
    sameAs: [
      "https://www.facebook.com/goalteller",
      "https://twitter.com/goalteller",
      "https://www.linkedin.com/company/goalteller",
    ],
    url: "https://goalteller.com",
  };
}
